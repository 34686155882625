import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step="6b"
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2>Diabetes Prevention Program (DPP) Study</H2>

          <p>Use the information in this reading to answer the questions on your handout, <em>Evaluating the Clinical Trials.</em></p>

          <Button
            action="secondary"
            className="m-2"
            color="indigo"
            href="/files/hs/lesson_2/reading-scl.final.aw.pdf"
            icon="download"
            title="Diabetes Prevention Program (DPP) study reading"
          >
            Diabetes Prevention Program (DPP) study reading
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
